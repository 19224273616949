<template>
  <validation-observer
    ref="creditClassTeacherSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="creditClassTeacherSaveModal"
      centered
      body-class="position-static"
      :title="isCreated ? `Thêm ${resourceName.fullName}` : `Cập nhật ${resourceName.fullName}`"
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-form-group label-for="departmentId">
          <template v-slot:label>
            Khoa/Bộ môn <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Khoa/Bộ môn"
            rules="required"
          >
            <v-select
              v-model="targetData.departmentId"
              :options="departments"
              :reduce="option => option.value"
              @input="onDepartmentChange"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="teacherId">
          <template v-slot:label>
            Giảng viên <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Giảng viên"
            rules="required"
          >
            <v-select
              v-model="targetData.teacherId"
              :options="teachers"
              :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Số tiết dạy"
          label-for="lessonNum"
        >
          <b-form-input
            id="lessonNum"
            v-model="targetData.lessonNum"
            name="maxQty"
          />
        </b-form-group>
        <b-form-group label-for="status">
          <template v-slot:label>
            Trạng thái <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Trạng thái"
            rules="required"
          >
            <v-select
              v-model="targetData.status"
              :options="statusOptions"
              :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="orderNo">
          <template v-slot:label>
            Thứ tự <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Thứ tự"
            rules="required"
          >
            <b-form-input
              id="orderNo"
              v-model="targetData.orderNo"
              name="orderNo"
              placeholder="Nhập thứ tự"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('creditClassTeacherSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BModal, BOverlay, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import * as events from 'events'
import { getUser } from '@/auth/utils'

export default {
  name: 'CreditClassTeacherSave',
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    mainData: {
      type: Object,
      default: undefined,
    },
    extData: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      targetData: {
        id: null,
        creditClassId: this.extData.creditClassId || this.$route.params.id,
        departmentId: null,
        teacherId: null,
        lessonNum: null,
        status: 1,
        orderNo: null,
      },
      required,
    }
  },
  computed: {
    events() {
      return events
    },
    ...mapGetters({
      statuses: 'creditClassTeacher/statuses',
      resourceName: 'creditClassTeacher/resourceName',
      maxOrderNo: 'creditClassTeacher/maxOrderNo',
      departments: 'dropdown/departments',
      teachers: 'dropdown/teachers',
    }),
    statusOptions() {
      return this.statuses
    },
    isCreated() {
      return !this.mainData
    },
  },
  methods: {
    ...mapActions({
      createData: 'creditClassTeacher/createData',
      updateData: 'creditClassTeacher/updateData',
      getMaxOrderNo: 'creditClassTeacher/getMaxOrderNo',
      getDepartments: 'dropdown/getDepartments',
      getTeachers: 'dropdown/getTeachers',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onDepartmentChange(event) {
      this.targetData.teacherId = null
      await this.getTeachers({ departmentId: event })
    },
    async onShow() {
      this.isLoading = true
      const cUser = getUser()
      const userOrgId = (cUser.orgId ? cUser.orgId : 0)
      await this.getDepartments({ organizationId: userOrgId })
      if (this.mainData) {
        this.targetData = this.mainData
      }
      if (this.isCreated) {
        await this.getMaxOrderNo(this.extData.creditClassId || this.$route.params.id)
        this.targetData.departmentId = this.departments[0].value
        this.targetData.orderNo = this.maxOrderNo + 1
        this.targetData.status = 1
      }
      await this.getTeachers({ departmentId: this.targetData.departmentId })
      this.isLoading = false
    },
    onHide() {
      this.$refs
        .creditClassTeacherSaveFormRef
        .reset()
      this.targetData = {
        id: null,
        creditClassId: this.extData.creditClassId || this.$route.params.id,
        departmentId: null,
        teacherId: null,
        lessonNum: null,
        status: 1,
        orderNo: '1_asc',
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .creditClassTeacherSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createData(this.targetData)
            : await this.updateData(this.targetData)
          if (response) {
            const {
              isSuccessful,
              message,
            } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('creditClassTeacherSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
